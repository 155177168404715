:root {
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 1em;
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em;  /* must NOT be a percentage value */
     /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1200px;
     /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --base-font-family: Hind, sans-serif;
    --alternative-font-family: var(--base-font-family);
}

@import "/fontmodules/Roboto.css";
@import "/fontmodules/RobotoCondensed.css";
@import "/fontmodules/DroidSerif.css";
@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/magnific-popup.css";
/*@import "/cssmodules/animate.css";*/



/*----------------------------------- General --------------------------------------*/

img {
    border: 0;
    max-width: 100%;
    height: auto;
}


.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}



body {
    color:#000000;
    background: #000000;
    font-family: "RobotoCondensed", san-serif;
    /* font-weight's att välja mellan är 100,300,400,500,700,900 */
}


header {
    background: rgba(0, 0, 0, 0.8);
    z-index: 100;
    position: fixed;
    top: 0;
    height:52px;
    width: 100%;
}


.hemisdaochdesignav  {
    background: rgba(0,0,0,.6);
    padding: 8px 20px 8px 8px;

}

.hemisdaochdesignav p, .hemisdaochdesignav a {
    font-family: "Roboto", sans-serif;
    color:#FFFFFF;
    font-size: 0.8rem;
    padding:0px;
    margin:0px;
}


.kontaktbanner {
    background: rgba(0,0,0,.6);
    color: #ffffff;
    padding-top: 30px;
    padding-bottom: 10px;
}

/*----------------------------------- Logotype --------------------------------------*/



.logo img {
    position: absolute;
    top: 5px;
    left: 20px;
    height: 100px;
    max-height: 100px;
    width: auto;
}


/* ---------------------------------- Rubrikerna h1 h2 h3 h4 h5 h6 --------------------------- */

.contaktcontent h1 {
    font-size: 1.35rem;
}


h1 {
    font-family: "Roboto", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
}

h2 {
    font-family: "Roboto", sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 1px;
}

h3 {
    font-family: "Roboto", sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    letter-spacing: 1px;
}

h4 {
    font-family: "Roboto", sans-serif;
    font-size: 1.4rem;
    font-weight: 300;
    letter-spacing: 1px;
}

h5 {
    font-family: "Roboto", sans-serif;
    font-size: 1.3rem;
    font-weight: 300;
    letter-spacing: 1px;
}

h6 {
    font-family: "Roboto", sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
    letter-spacing: 1px;
}

p  {

    line-height: 150%;
    font-family: "RobotoCondensed", sans-serif;
    font-size: 1.05em;
    font-weight: 300;
    line-height: 150%;
    
}

.pagecontent ul {
    line-height: 150%;
    font-family: "RobotoCondensed", sans-serif;
    font-size: 1.05em;
    font-weight: 300;
    line-height: 150%;
    padding-left:1vw;
}


/*-------------------------------------------------------------- Bildspel ------------------------------------------------------------------------*/

.slick-initialized .slick-slide{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.slick-slider {
    margin-top: 0px;
    position: fixed;
    width:100%; 
    top:0;
    z-index: -10; /* z-index - sätter vilken "nivå" bildspelet / "vad man vill" ska hamna på */
}

.slick-slide {
    height: 100vh;
    width:100%;
    background-size: cover;
    background-position: top center ;
}

.slideshow_titel {
    font-family: 'Roboto',arial,san-serif;
    background: rgba(51, 51, 51, 0.25);
    font-weight: 100;
    font-size: 2.8em;
    position: relative;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 3px;
    text-align: center;
    margin-top: 50vh;
    margin-bottom: 0;
    z-index: -8;
    width: 100%;
    height: 60px;
    border-style: solid;
    border-width: 2px;
    border-left: 0;
    border-right: 0;
    border-color: #fff;
}

/*-------------------------------------------------------------- Topimage / Toppbild ------------------------------------------------------------------------*/
.topimage {
    margin-top: 0px;
    position: fixed;
    width:100%; 
    height:100vh;
    top:0;
    z-index: -10; 
    background-size: cover;
    background-position: top center;
}


/*-------------------------------------------------------------- Pagecontent / Innehåll sida och startsida ------------------------------------------------------------------------*/
.pagecontentstart {
        padding-top: 3vh;
}

.pagecontent {
    border-top: solid 10px #000000;
    border-bottom: solid 10px #000000;
    margin-top:70vh;
    position: relative;
    z-index: 15;
    background: rgb(255, 255, 255);
}

.bodycol {
    margin-top:0vh;
    padding-top:0vh;
}

.bodycol img {
    border: solid 3px #000000;
    margin-top:3vh;
}

.pagetitle h1 {
    margin-bottom:0vh;
    padding-bottom:0vh;
}
.pagetitle {
    margin-bottom:0vh;
    padding-bottom:0vh;
}

.pdfloop  {
    color:#487d92;
    line-height: 150%;
    font-family: "RobotoCondensed", sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 150%;
    margin-top:3vh;
}

.pdfloop a {
    color:#487d92;
}

.pdfloop svg {
    fill: #487d92;
}


.margin>* {
margin-top:0px;
}

.kontaktlankboxbild {
    min-height: 250px;
    max-height: 250px;
    position: relative;
    background-size: cover;
    background-position: center center;
}

a.kontaktlankboxbild{
    display:block;
}

/*------------------------- Logobanner ---------------------------*/

.logobanner {
    padding-top: 6vh;
    padding-bottom: 3vh;
}

/*---------- Gallery galleri galleries --------------*/

.gallerybackground {
    padding-top:2vh;
background: rgb(0,0,0);
}

.imagelist_link {
    min-height: 500px;
    width: 100%;
    position: relative;
    background-size: cover;
    background-position: center center;
}

a.imagelist_link{
    display:block;
}


/*----------------------------------- Länkarna --------------------------------------*/

a {
    text-decoration: none;
}

a:active, a:hover {
    text-decoration: underline;
}

/*----------------------------------- Meny / Menu --------------------------------------*/

nav li{
    background: none;
}

nav {
    font-size:0.8em;
    color:#CCCCCC;
    float:right;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 1.1em;
}

.menu li a,
.menu .dropdown ul
{
    text-decoration: none;
}

.menu li {
     /* osynlig / transparent för att ta samma utrymme som active*/
}
.menu>li {
    margin: 0 1em;
    list-style: none;
    padding: 8px 8px 8px;
}
.menu .active,
.menu .current,
.menu .activeparent,
.menu li:hover
{
    color:#f8d109;
    text-decoration: none;
}


/* ------------------------------------------------ DropDownMenyn / Drop Down Menu -------------------------------------------- */

 .menu .dropdown ul{
    top: calc(100% + 1px);
    left: 26px;
    background: rgba(255, 255, 255, 0.9);
    border-bottom:none;
    border-width: 0px;
}


.menu .dropdown li {
    white-space: nowrap;
    background: none;
}

.menu .dropdown li a {
    min-width: 100px;
    padding: 8px 2px 5px 2px;
    margin-left: 10px;
    margin-right: 10px;
}



/*------------------------------------- Moduler / Modul / Länkboxar ---------------*/


.moduleboxes {
    background: #FFFFFF;
    padding-top:1vh;
    padding-bottom:1vh;
}

.moduleboxwrapper {
    border: solid 3px #000;
    position: relative;
    overflow: hidden; 
    cursor: pointer; 
    margin:0; 
    width:100%;
}

.moduleboximage {
    height: 365px;
    position: relative;
    display:block;
}



.moduleboximage > div{
    color: #f8d109;
    text-transform: uppercase;
    background-color: rgba(0,0,0, 0.6);
    font-family: "Roboto", sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    position:absolute; 
    text-align: center;
    max-width: none;
    will-change: transform;
}

.coverimage{
    transform-origin: 50% 50%;
    background-size: cover;
    background-position: center;
    transform: scale(1.0);
    transition: transform 0.5s ease-out 0s;
    top:0;
    right:0;
    bottom:0;
    left:0;
}
.moduleboximage:hover .coverimage {
    transform: scale(1.02);
    
}


.boxtitle1 {
    padding: 20px 0 20px 0;
    left: -2px;
    right: -2px;
    color: #666666;
    top:45%;
    font-weight: 300;
    font-size: 1.5rem;
    transition: transform 0.3s ease-out;
}

.moduleboxwrapper:hover .boxtitle1 {
    transform: translateX(-20%);
}









/* --------------------------------------------------------- Kontaktformulär / Kontaktbanner ----------------------------------------- */

.boltforms-row label {
    font-weight: 400;
    margin-bottom: 0;
}

.boltforms-row input {
    width: 100%;
    color: #606060;
}

.boltforms-row textarea {
    width: 100%;
    color: #606060;
    height: 16vh;
}

.boltforms-row button {
    display: block;
    width: 100%;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.2);
    margin: 0;
    border: none;
    border-radius: 2px;
    height: auto;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.1em;
}

/*--------------------- Kontaktbanner-texten ----------------------*/


.socialmedia {
    font-size:1.3rem;
    letter-spacing: 5px;
}

.kontaktbanner a {
    color: #ffffff;
}

.kontaktbanner h5 {
    color: #ffffff;
    font-size: 1.5rem;
    font-weight:500;
    margin-top: 18px;
    margin-bottom: 2vh;
}

.kontaktbanner h2 {
    padding: 0px 0 10px;
}

.kontaktbanner p {
    font-size: 1.1em;
    margin-bottom: 0px;
    line-height: 150%;
}

.kontaktbanner .boltforms-row {
    float: left;
    width: 33.3%;
    padding-right: 10px;
}

.kontaktbanner .boltforms-row:nth-child(n+4) {
    width: 100%;
}

button[type=submit], input[type=submit] {
    background: #FFFFFF;
    color: #000000;
}

button[type=submit]:hover, input[type=submit]:hover {
    background: #FFFFFF;
    color: #000000;
}

/*------------------Give map a height and it's images no max-width----------*/
    .map-canvas {
    height: 27vh;
}

.map-canvas img {
    max-width: none;
}


.markerLabels{
    overflow:visible !important;
}

.fa-map-marker{
    background: url(/extensions/local/intendit/intenditExt/web/black/map-marker.svg);
    background-size: cover;
    padding: 10px;
    background-position: center;
}

/*------------------------------------------------------------------------------------------------ 
MEDIA QUERIES ----------------------------------------------------------------------------------*/

/*Allt under M-nivå*/
@media (max-width: var(--breakpoint-m)) {
    
    .menu .dropdown ul {
    background: rgba(0, 35, 85, 0.0);
    }
    
    .menu.show {
    background: rgba(0,0,0,.6);
}
    
} /* SLUT - Allt under M-nivå*/ 

/*Allt under S-nivå*/
@media (max-width: var(--breakpoint-s)) {
    
  
}  

/*Allt under XS-nivå*/
@media (max-width: var(--breakpoint-xs)) {
    
    
}

@media screen and (orientation: landscape) and (max-width: 800px) {


}

@media screen and (orientation: landscape) and (max-width: 800px) {

    
}

/*--------------------------Make FB widget responsive------------------------*/
    .fb_iframe_widget,
    .fb_iframe_widget span,
    .fb_iframe_widget iframe[style],
    .twitter-timeline {
        width: 100% !important;
    }
